<template>
  <div>
    <div class="search">
      <el-select v-model="search.state" @change="handleChangeStatusGoods">
        <el-option label="未上架" value="false"></el-option>
        <el-option label="已上架" value="true"></el-option>
      </el-select>

      <div class="search_recommend">
        精选商品&nbsp;&nbsp;
        <el-radio
          v-model="search.isRecommend"
          @change="handleChangeIsRecommend"
          label=""
          >全部</el-radio
        >
        <el-radio
          v-model="search.isRecommend"
          @change="handleChangeIsRecommend"
          label="true"
          >是</el-radio
        >
        <el-radio
          v-model="search.isRecommend"
          @change="handleChangeIsRecommend"
          label="false"
          >否</el-radio
        >
      </div>

      <el-input placeholder="请输入商品名称" v-model="search.name"></el-input>

      <div>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
      <div style="margin-left: 40px">
        <el-button @click="handleBatchBtn('up')" v-if="search.state == 'false'"
          >批量上架</el-button
        ><el-button
          @click="handleBatchBtn('down')"
          v-if="search.state == 'true'"
          >批量下架</el-button
        >
      </div>
    </div>

    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        id="mytable"
      >
        <el-table-column type="selection" width="40" align="center">
        </el-table-column>
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="goodsId" label="商品id" width="150">
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称" width="230">
          <template slot-scope="scope">
            <div>中文：{{ scope.row.goodsName }}</div>
            <div v-if="scope.row.goodsNameTw">
              繁体：{{ scope.row.goodsNameTw }}
            </div>
            <div v-if="scope.row.goodsNameUs">
              英文：{{ scope.row.goodsNameUs }}
            </div>
            <div v-if="scope.row.goodsNameKr">
              韩文：{{ scope.row.goodsNameKr }}
            </div>
            <div v-if="scope.row.goodsNameJp">
              日文：{{ scope.row.goodsNameJp }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goodsPrice" label="商品价格" width="170">
        </el-table-column>
        <el-table-column
          prop="primaryImage"
          label="商品主图"
          width="290"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              style="height: 150px"
              :src="scope.row.primaryImage"
              :preview-src-list="[scope.row.primaryImage]"
              v-if="scope.row.primaryImage"
            >
            </el-image>
            <div v-if="!scope.row.primaryImage">暂无图片</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="isRecommend"
          label="是否为精选商品"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.isRecommend">
              是
              <el-button
                style="margin-top: 10px"
                @click="handleCommend(scope.row, 'cancel')"
                plain
                type="danger"
                >取消精选商品</el-button
              >
            </div>
            <div v-if="!scope.row.isRecommend">
              否
              <el-button
                style="margin-top: 10px"
                @click="handleCommend(scope.row, 'set')"
                plain
                type="success"
                >设为精选商品</el-button
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <el-button
                type="text"
                v-if="search.state == 'false'"
                @click="handleClick(scope.row, 1)"
                >立即上架</el-button
              ><el-button
                type="text"
                v-if="search.state == 'true'"
                @click="handleClick(scope.row, 2)"
                >立即下架</el-button
              >
              <el-button
                type="text"
                style="margin-left: 0"
                @click="handleDialogDetail(scope.row)"
                >查看详情</el-button
              >
              <el-button
                type="text"
                style="margin-left: 0"
                @click="handleEditGoods(scope.row)"
                >修改商品详情</el-button
              >
              <el-button
                type="text"
                style="margin-left: 0"
                @click="handleEdit(scope.row, 'all')"
                >编辑商品规格</el-button
              >
              <el-popover placement="bottom" trigger="click">
                <el-button
                  type="primary"
                  @click="handleEdit(scope.row, 'stock')"
                  >修改库存</el-button
                >
                <el-button
                  type="primary"
                  @click="handleEdit(scope.row, 'price')"
                  >修改价格</el-button
                >
                <el-button
                  type="primary"
                  @click="handleEdit(scope.row, 'status')"
                  >修改状态</el-button
                >
                <el-button slot="reference" type="text">更多操作</el-button>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        title="商品详情"
        :visible.sync="dialogVisible"
        width="80%"
        @close="handleCloseDialogRefresh"
      >
        <div>
          <div class="dialog_box">
            <h3>商品id：{{ dialog.goodsId }}</h3>
          </div>
          <div class="dialog_box">
            <div style="display: flex; align-items: center">
              <h3>商品详情：（中文）</h3>
              <iframe
                :src="dialog.goodsDesc"
                style="width: 80%; border: 1px #ccc solid"
                frameborder="0"
              ></iframe>
            </div>
            <div
              style="display: flex; align-items: center"
              v-if="dialog.goodsDescTw"
            >
              <h3>商品详情：（繁体）</h3>
              <iframe
                :src="dialog.goodsDescTw"
                style="width: 80%; border: 1px #ccc solid"
                frameborder="0"
              ></iframe>
            </div>
            <div
              style="display: flex; align-items: center"
              v-if="dialog.goodsDescUs"
            >
              <h3>商品详情：（英文）</h3>
              <iframe
                :src="dialog.goodsDescUs"
                style="width: 80%; border: 1px #ccc solid"
                frameborder="0"
              ></iframe>
            </div>
            <div
              style="display: flex; align-items: center"
              v-if="dialog.goodsDescKr"
            >
              <h3>商品详情：（韩文）</h3>
              <iframe
                :src="dialog.goodsDescKr"
                style="width: 80%; border: 1px #ccc solid"
                frameborder="0"
              ></iframe>
            </div>
            <div
              style="display: flex; align-items: center"
              v-if="dialog.goodsDescJp"
            >
              <h3>商品详情：（日文）</h3>
              <iframe
                :src="dialog.goodsDescJp"
                style="width: 80%; border: 1px #ccc solid"
                frameborder="0"
              ></iframe>
            </div>
          </div>
          <div class="dialog_box">
            <h3 style="margin-bottom: 15px">商品图片</h3>
            <div style="display: flex; align-items: center">
              <div v-for="(item, i) in dialog.imageUrlList" :key="i">
                <el-image
                  style="width: 200px; margin-right: 15px"
                  :src="item"
                  :preview-src-list="[item]"
                  v-if="dialog.imageUrlList"
                >
                </el-image>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- <Pagination
            v-show="pageLength >= 0"
            :total="pageLength"
            :limit.sync="pageSize"
            @pagination="handlePageChange"
            :page-sizes="pageSizes"
          /> -->
    </div>
  </div>
</template>

<script>
import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

export default {
  inject: ["reload"],
  data() {
    return {
      search: {
        state: "false",
        name: "",
        isRecommend: "",
      },

      tableData: [],
      multipleSelection: [],

      dialogVisible: false,
      dialog: {
        goodsId: "",
        goodsDesc: "",
        goodsDescTw: "",
        goodsDescUs: "",
        goodsDescKr: "",
        goodsDescJp: "",
        imageUrlList: [],
      },

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    this.search.state = localStorage.getItem("goodsListSearchStatus");
    showLoading();
    const opt = {
      url: reqUrl.getGoodsInfoList,
      method: "POST",
      params: JSON.stringify({
        goodsName: "",
        goodsState: this.search.state,
        isRecommend: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          hideLoading();
          this.tableData = res.data.data.list;
          // this.pageLength = res.data.data.total;
          // this.pageSize = res.data.data.pageSize;
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    handleChangeStatusGoods(value) {
      localStorage.setItem("goodsListSearchStatus", value);
      showLoading();
      const opt = {
        url: reqUrl.getGoodsInfoList,
        method: "POST",
        params: JSON.stringify({
          goodsName: "",
          goodsState: this.search.state,
          isRecommend: this.search.isRecommend,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();
            this.tableData = res.data.data.list;
            // this.pageLength = res.data.data.total;
            // this.pageSize = res.data.data.pageSize;
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 查询是否为精选商品
    handleChangeIsRecommend() {
      showLoading();
      const opt = {
        url: reqUrl.getGoodsInfoList,
        method: "POST",
        params: JSON.stringify({
          goodsName: "",
          goodsState: this.search.state,
          isRecommend: this.search.isRecommend,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();
            this.tableData = res.data.data.list;
            // this.pageLength = res.data.data.total;
            // this.pageSize = res.data.data.pageSize;
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 上下架
    handleClick(row, index) {
      let idsValue = [];
      idsValue.push(row.goodsId);
      if (index == 1) {
        this.$confirm("是否确认立即上架?", "立即上架", {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          showLoading();
          const opt = {
            url: reqUrl.onSaleOrOffSale,
            method: "POST",
            params: JSON.stringify({
              ids: idsValue,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                this.$message({
                  message: "上架商品成功",
                  type: "success",
                });
                hideLoading();
                this.refresh();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        });
      } else if (index == 2) {
        this.$confirm("是否确认立即下架？下架后请到“仓库”中查看", "立即下架", {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          showLoading();
          const opt = {
            url: reqUrl.onSaleOrOffSale,
            method: "POST",
            params: JSON.stringify({
              ids: idsValue,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                this.$message({
                  message: "下架商品成功",
                  type: "success",
                });
                hideLoading();
                this.refresh();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        });
      }
    },

    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.getGoodsInfoList,
        method: "POST",
        params: JSON.stringify({
          goodsName: this.search.name,
          goodsState: this.search.state,
          isRecommend: this.search.isRecommend,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();
            this.tableData = res.data.data.list;
            // this.pageLength = res.data.data.total;
            // this.pageSize = res.data.data.pageSize;
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    reset() {
      this.search.name = "";

      showLoading();
      const opt = {
        url: reqUrl.getGoodsInfoList,
        method: "POST",
        params: JSON.stringify({
          goodsName: "",
          goodsState: "",
          isRecommend: "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();
            this.tableData = res.data.data.list;
            // this.pageLength = res.data.data.total;
            // this.pageSize = res.data.data.pageSize;
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 批量
    handleBatchBtn(index) {
      console.log(this.multipleSelection, "this.ad");
      if (this.multipleSelection.length > 0) {
        let batchIds = [];
        this.multipleSelection.map((item) => {
          batchIds.push(item.goodsId);
        });
        if (index == "up") {
          this.$confirm("是否确认立即上架?", "立即上架", {
            distinguishCancelAndClose: true,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            showLoading();
            const opt = {
              url: reqUrl.onSaleOrOffSale,
              method: "POST",
              params: JSON.stringify({
                ids: batchIds,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                } else if (res.data.code == 0) {
                  this.$message({
                    message: "上架商品成功",
                    type: "success",
                  });
                  hideLoading();
                  this.refresh();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                hideLoading();
                console.log(err, "err1");
              },
            };
            request(opt);
          });
        } else if (index == "down") {
          this.$confirm("是否确认立即下架?", "立即下架", {
            distinguishCancelAndClose: true,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            showLoading();
            const opt = {
              url: reqUrl.onSaleOrOffSale,
              method: "POST",
              params: JSON.stringify({
                ids: batchIds,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                } else if (res.data.code == 0) {
                  this.$message({
                    message: "下架商品成功",
                    type: "success",
                  });
                  hideLoading();
                  this.refresh();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                hideLoading();
                console.log(err, "err1");
              },
            };
            request(opt);
          });
        }
      } else {
        this.$notify.error({
          title: "错误",
          message: "请至少选择一项进行上架哦",
        });
      }
    },

    handleDialogDetail(row) {
      this.dialogVisible = true;
      const opt = {
        url: reqUrl.getGoodsDetailById,
        method: "POST",
        params: JSON.stringify({
          id: row.goodsId,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
          if (res.data.code == 0) {
            this.dialog.goodsId = res.data.data.goodsId;
            this.dialog.goodsDesc = res.data.data.goodsDesc;
            this.dialog.goodsDescTw = res.data.data.goodsDescTw;
            this.dialog.goodsDescUs = res.data.data.goodsDescUs;
            this.dialog.goodsDescKr = res.data.data.goodsDescKr;
            this.dialog.goodsDescJp = res.data.data.goodsDescJp;
            this.dialog.imageUrlList = res.data.data.imageUrlList;
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handleCloseDialogRefresh() {
      // this.refresh();
    },

    // 编辑和更多
    handleEdit(row, type) {
      if (type == "all") {
        this.$router.push({
          path: "/GoodsShelves/edit/goods",
          query: {
            goodsId: row.goodsId,
            type: this.$route.query.type,
            s: 0,
          },
        });
      } else if (type == "stock") {
        this.$router.push({
          path: "/GoodsShelves/edit/goods",
          query: {
            goodsId: row.goodsId,
            type: this.$route.query.type,
            s: 1,
          },
        });
      } else if (type == "price") {
        this.$router.push({
          path: "/GoodsShelves/edit/goods",
          query: {
            goodsId: row.goodsId,
            type: this.$route.query.type,
            s: 2,
          },
        });
      } else if (type == "status") {
        this.$router.push({
          path: "/GoodsShelves/edit/goods",
          query: {
            goodsId: row.goodsId,
            type: this.$route.query.type,
            s: 3,
          },
        });
      }
    },

    // 设为精选/不精选
    handleCommend(row, index) {
      let commendIds = [];
      commendIds.push(row.goodsId);
      if (index == "set") {
        this.$confirm("您是否确认设为精选商品?", "设为精选商品", {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          showLoading();
          const opt = {
            url: reqUrl.recommendOrNotGoods,
            method: "POST",
            params: JSON.stringify({
              ids: commendIds,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                this.$message({
                  message: "设为精选商品成功",
                  type: "success",
                });
                hideLoading();
                this.refresh();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        });
      } else if (index == "cancel") {
        this.$confirm("您是否确认取消当前精选商品?", "取消精选商品", {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          showLoading();
          const opt = {
            url: reqUrl.recommendOrNotGoods,
            method: "POST",
            params: JSON.stringify({
              ids: commendIds,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                this.$message({
                  message: "取消精选商品成功",
                  type: "success",
                });
                hideLoading();
                this.refresh();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        });
      }
    },

    // 修改商品详情
    handleEditGoods(row) {
      const opt = {
        url: reqUrl.getGoodsDetailById,
        method: "POST",
        params: JSON.stringify({
          id: row.goodsId,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
          if (res.data.code == 0) {
            console.log(row, "row");
            this.$router.push("/GoodsShelvesEdit");

            let obj = {};
            obj = {
              ...row,
            };
            row.goodsDesc = res.data.data.goodsDesc;
            row.goodsDescJp = res.data.data.goodsDescJp;
            row.goodsDescKr = res.data.data.goodsDescKr;
            row.goodsDescTw = res.data.data.goodsDescTw;
            row.goodsDescUs = res.data.data.goodsDescUs;

            row.goodsDescEdit = res.data.data.goodsDescEdit;
            row.goodsDescJpEdit = res.data.data.goodsDescJpEdit;
            row.goodsDescKrEdit = res.data.data.goodsDescKrEdit;
            row.goodsDescTwEdit = res.data.data.goodsDescTwEdit;
            row.goodsDescUsEdit = res.data.data.goodsDescUsEdit;
            row.imageUrlList = res.data.data.imageUrlList;
            localStorage.setItem("rowEditGoodsInfo", JSON.stringify(row));
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  .el-input {
    width: 250px;
    margin: 0 20px;
  }

  .el-select {
    width: 120px;
    margin-right: 20px;
  }

  .search_recommend {
    background: #fff;
    padding: 8px 20px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    color: #606266;
    font-size: 14px;
  }
}

.dialog_box {
  background: rgb(239, 239, 239);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 13px;

  a {
    color: #409eff;
  }
}
</style>
